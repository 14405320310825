/*
  listen.ts
  (c) Human Cube Inc.
*/

import { store } from '../../app/store';
import { addText, setStarted, setStatus, setTopic } from './listenSlice';

// @ts-ignore
var SpeechRecognition: any = window.SpeechRecognition || window.webkitSpeechRecognition;
// @ts-ignore
// var SpeechGrammarList: any = window.SpeechGrammarList || window.webkitSpeechGrammarList;
// @ts-ignore
// var SpeechRecognitionEvent: any = window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;

const API_BASE_URL = 'https://api1.humancubed.com/v1/mindwords/';
// const API_BASE_URL = 'http://127.0.0.1:9000/v1/mindwords/';
const MAX_FINDS_PER_SET = 5;

var continueListening = true;

// Keys of url of items already displayed:
interface AlreadyDisplayed {
  [index: string]: boolean;
}
const alreadyDisplayed: AlreadyDisplayed = {};

export function stopListening() {
  if (continueListening) {
    continueListening = false;
    store.dispatch(setStatus('Stopping'));
  }
}

export function startListening() {
  store.dispatch(setStarted(true));
  store.dispatch(setStatus('Starting'));

  continueListening = true;

  // var grammar = '#JSGF V1.0; grammar phrase; public <phrase> = ' + phrase + ';';
  let recognition = new SpeechRecognition();
  // var speechRecognitionList = new SpeechGrammarList();
  // speechRecognitionList.addFromString(grammar, 1);
  // recognition.grammars = speechRecognitionList;
  recognition.lang = 'en-US';
  recognition.interimResults = false;
  recognition.maxAlternatives = 1;

  // @ts-ignore
  window.flyingOn = true;
  recognition.start();

  recognition.onresult = async function (event: any) {
    // The SpeechRecognitionEvent results property returns a SpeechRecognitionResultList object
    // The SpeechRecognitionResultList object contains SpeechRecognitionResult objects.
    // It has a getter so it can be accessed like an array
    // The first [0] returns the SpeechRecognitionResult at position 0.
    // Each SpeechRecognitionResult object contains SpeechRecognitionAlternative objects that contain individual results.
    // These also have getters so they can be accessed like arrays.
    // The second [0] returns the SpeechRecognitionAlternative at position 0.
    // We then return the transcript property of the SpeechRecognitionAlternative object 
    const speechResult = event.results[0][0].transcript.toLowerCase();
    const confidence = event.results[0][0].confidence;

    // console.log('Heard: ' + speechResult);
    // console.log('Confidence: ' + confidence);

    store.dispatch(addText('\n' + confidence + '\t' + speechResult));

    if(speechResult) {
      const url = API_BASE_URL + encodeURIComponent(speechResult);
      try {
        const res = await fetch(url);
        const json = await res.json();
        if (json && json.urls && json.urls.length > 0) {
          let foundCount = 0;
          const urls: string[] = [];
          for (let i = 0; i < json.urls.length; i++) {
            // const key = json.urls[i][0];
            const key = json.urls[i][1];  // Use the URL as the key to handle plurals as same.
            const url = json.urls[i][1];
            if (key && url && !alreadyDisplayed[key]) {
              alreadyDisplayed[key] = true;
              urls.push(url);
              foundCount++;
              if(foundCount >= MAX_FINDS_PER_SET) {
                break;  // found enough.
              }
            }
          }
          for (let u = urls.length - 1; u >= 0; u--) {
            const url = urls[u];
            const res2 = await fetch(url);
            const json2 = await res2.json();
            if (json2 && json2.thumbnail && json2.thumbnail.source &&
              json2.content_urls && json2.content_urls.mobile && json2.content_urls.mobile.page) {
              store.dispatch(setTopic([
                json2.thumbnail.source,
                json2.extract_html,
                json2.content_urls.mobile.page,
              ]));
            }
          }
        }
      } catch (error) {
        console.error('Fetch error:', error);
      }
    }
  }

  recognition.onspeechend = function () {
  }

  recognition.onerror = function (event: any) {
    console.log('Error occurred in recognition: ' + event.error);
    // store.dispatch(setStarted(false));
  }

  recognition.onaudiostart = function (event: any) {
    // Fired when the user agent has started to capture audio.
    // console.log('SpeechRecognition.onaudiostart');
    store.dispatch(setStatus('Listening'));
  }

  recognition.onaudioend = function (event: any) {
    // Fired when the user agent has finished capturing audio.
    // console.log('SpeechRecognition.onaudioend');
  }

  recognition.onend = function (event: any) {
    // Fired when the speech recognition service has disconnected.
    // console.log('SpeechRecognition.onend');

    if (continueListening) {
      recognition.start();
    }
    else {
      // @ts-ignore
      window.flyingOn = false;
      store.dispatch(setStatus('Stopped'));
      store.dispatch(setStarted(false));
    }
  }

  recognition.onnomatch = function (event: any) {
    //Fired when the speech recognition service returns a final result with no significant recognition. This may involve some degree of recognition, which doesn't meet or exceed the confidence threshold.

    console.log('SpeechRecognition.onnomatch');
  }

  recognition.onsoundstart = function (event: any) {
    //Fired when any sound — recognisable speech or not — has been detected.

    // console.log('SpeechRecognition.onsoundstart');
  }

  recognition.onsoundend = function (event: any) {
    //Fired when any sound — recognisable speech or not — has stopped being detected.

    // console.log('SpeechRecognition.onsoundend');
  }

  recognition.onspeechstart = function (event: any) {
    //Fired when sound that is recognised by the speech recognition service as speech has been detected.
    // console.log('SpeechRecognition.onspeechstart');
    // store.dispatch(setStatus(''));
  }

  recognition.onstart = function (event: any) {
    //Fired when the speech recognition service has begun listening to incoming audio with intent to recognize grammars associated with the current SpeechRecognition.

    // console.log('SpeechRecognition.onstart');
  }
}
