/*
  Counter.tsx
  (c) Human Cube Inc.
*/

import React from 'react';
import { Modal, Row, Button, Text } from "@nextui-org/react";
import ReactList from 'react-list';

import { useAppSelector } from '../../app/hooks';

import { listenStarted, listenStatus, /* listenText, */ listenTopic } from '../listen/listenSlice';

import { startListening, stopListening } from '../listen/listen';


function Status () {
  const status = useAppSelector(listenStatus);
  if(!status) {
    return null;
  }
  const text = status + (status !== 'Stopped' ? '...' : '.');
  return (
    <div style={{ fontSize: '0.6em', color: 'whitesmoke'}}>{ text }</div>
  );
}


export function Counter() {
  const [visible, setVisible] = React.useState(true);
  const [detailsWebPage, setDetailsWebPage] = React.useState('');
  const closeHandler = () => {
    setVisible(false);
  };

  const started = useAppSelector(listenStarted);
  // const text = useAppSelector(listenText);
  const topic = useAppSelector(listenTopic);

  let info: any[] = [];
  for(let i = 0; i < topic.length; i++) {
    const imgURL = topic[i][0];
    const summary = topic[i][1];
    const webPage = topic[i][2];
    info.unshift(
      <div style={{ width: 782, height: 132, display: 'flex',
                    borderWidth: 1, borderColor: 'grey',
                    backgroundColor:
                      (i & 1) ? 'rgba(127, 127, 127, 0.5)' : 'rgba(150, 150, 150, 0.5)',
                    cursor: 'pointer',
                  }}
          onClick={ (e) => {topicClick(webPage)} }
        >
          <div style={{
            width: 150,
            height: 130,
            marginLeft: 6,
            backgroundImage: `url(${imgURL})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
          />
          <div style={{
            width: 640,
            height: 120,
            fontSize: 14,
            paddingLeft: 6,
            overflow: 'clip',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            flexDirection: 'row',
            color: 'whitesmoke',
          }} 
            dangerouslySetInnerHTML={{ __html: summary }}
          />
      </div>
    );
  }

  function topicClick(webPage: string) {
    if (webPage) {
      setDetailsWebPage(webPage);
    }
  }

  function renderItem(index: any, key: any) {
    return <div key={key}>{ info[index] }</div>;
  }

  return (
    <div>
      <div style={{ height: '100vh', width: 800, overflowY: 'auto', overflowX: 'hidden'}}>
        <ReactList
          itemRenderer={renderItem}
          length={info.length}
          type='uniform'
        />
      </div>

      <div style={{
        position: 'absolute',
        left: 3,
        top: 16,
        height: 64,
        width: 100,
        color: 'white',
      }}>
        Human&sup3;
      </div>

      <div style={{
        position: 'absolute',
        left: 3,
        top: 72,
        height: 72,
        width: 100,
        alignContent: 'center',
      }}>
        <Button
          style={{ marginLeft: 13 }}
          auto
          color={ started ? 'error' : 'success' }
          aria-label={started ? 'Stop' : 'Start'}
          onClick={() => {
            started ? stopListening() : startListening();
          }}
        >
          {started ? 'Stop' : 'Start'}
        </Button>
        <Status />
          {/* <div style={{ height: 60, width: 512 }}>
            <pre style={{ fontSize: 10, textAlign: 'left' }}>
              {text}
            </pre>
          </div> */}
      </div>

      <div style={{
        position: 'absolute',
        left: 3,
        bottom: 5,
        width: 100,
        overflow: 'hidden',
        alignContent: 'center',
        color: 'whitesmoke',
        fontSize: '0.6em',
        fontWeight: 'bold',
      }}>
        Thanks to Wikipedia for their continued effort to educate the world.
        <br />
        <br />
        { 'v' + process.env.REACT_APP_VERSION }
        <br />
        <br />
        Copyright &copy;
        <a href='https://humancube.com' target='_blank' rel='noreferrer'>
          Human Cube Inc.
        </a>
      </div>
      
      <Modal
        key='modalIntro'
        width='532px'
        preventClose
        blur
        aria-labelledby="welcome"
        open={visible}
        onClose={closeHandler}
        autoMargin={false}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Welcome to&nbsp;
            <Text b size={18}>
              Human&sup3;&nbsp;!
            </Text>
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Row justify="space-between">
            Augment your brain by allowing this app to
            display contextual information based on what
            it hears.
          </Row>
          <Row justify="space-between">
            Sometimes handy during online meetings. 😉
          </Row>
          <Row justify="space-between">
            <br />
            3 easy steps to get started:
          </Row>
          <Row justify="space-between">
            <ul>
              <li key={1}>Click the green Start button.</li>
              <li key={2}>When prompted, enable the microphone.</li>
              <li key={3}>Wait a few seconds, then start talking!</li>
            </ul>
          </Row>
          <Row justify="space-between">
            <b>Note:&nbsp;</b>Only the Chrome desktop browser is supported at this time.
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size='lg' onClick={closeHandler}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        key='modalWebPage'
        fullScreen
        blur
        aria-labelledby="details"
        open={detailsWebPage ? true : false}
        onClose={() => { setDetailsWebPage('')}}
      >
        <Modal.Body>
          <iframe
            title='Details'
            src={detailsWebPage}
            style={{ height: '96%', width: '96%' }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button size='lg' onClick={() => { setDetailsWebPage('')}}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
