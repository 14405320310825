/*
  App.tsx
  (c) Human Cube Inc.

  TODO:
    - Response from wikipedia api for "type":"disambiguation" items, need to handle, as they are
      currently just dropped for now.
*/

import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Counter } from './features/counter/Counter';
import './App.css';


function Mind() {
  return (
    <header className="App-header">
      <Counter />
    </header>
  );
}

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Mind />} />
        {/* <Route path="mind" element={<Mind />} /> */}
      </Routes>
    </div>
  );
}

export default App;
