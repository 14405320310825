/*
  listenSlice.ts
*/

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface CounterState {
  started: boolean,
  status: string,
  text: string;
  topic: string[][];
}

const initialState: CounterState = {
  started: false,
  status: '',
  text: '',
  topic: [],
};


export const listenSlice = createSlice({
  name: 'listen',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addText: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.text = action.payload + state.text;
    },
    setStarted: (state, action: PayloadAction<boolean>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.started = action.payload;
    },
    setStatus: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.status = action.payload;
    },
    setTopic: (state, action: PayloadAction<string[]>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.topic.push(action.payload);
    },
  },
  // // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = 'idle';
  //       state.value += action.payload;
  //     })
  //     .addCase(incrementAsync.rejected, (state) => {
  //       state.status = 'failed';
  //     });
  // },
});

export const { addText, setStarted, setStatus, setTopic } = listenSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const listenStarted = (state: RootState) => state.listen.started;
export const listenStatus = (state: RootState) => state.listen.status;
export const listenText = (state: RootState) => state.listen.text;
export const listenTopic = (state: RootState) => state.listen.topic;

export default listenSlice.reducer;
